import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js";
import Seo from "../components/seo"
import HeroTitle from "../components/hero-title/hero-title"
import $ from 'jquery'

class PageTemplate extends Component {
	componentDidMount() {
		setTimeout(function(){

			var iFrameID = document.getElementById('dynamic-height');
			if(iFrameID) {
				// here you can make the height, I delete it first, then I make it again
				iFrameID.height = "";
				iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
			}
		}, 2000 );

		$('.header-links .header-link').each(function() {
			if($(this).attr('href') === window.location.pathname) {
				$(this).addClass('active');
			}
		});
		if (window.location.pathname.indexOf('/about/') >= 0) {
			$('.header-links .header-link[href="/about/"]').addClass('active')
		}
	}
	render() {
		const currentPage = this.props.data.wpPage
		const aboutSubmenu = this.props.data.wpMenu
		return (
			<Layout>
			<Seo
				title={currentPage.title}
				contentBody={currentPage.body}
				locationOrigin={this.props.location.origin + currentPage.uri}
				domen={this.props.location.origin}
				image={currentPage.featuredImage ?  currentPage.featuredImage.node.sourceUrl.replace(process.env.WP_SITE_URL,"/") : null}
			/>
				{currentPage.slug === 'about' || (currentPage.wpParent && currentPage.wpParent.node.slug === 'about') ? (
					<div className="sub-menu">
						<div className="container">
							<ul>
								{aboutSubmenu.menuItems.nodes.map(item => (
									<li key={item.url}>
										<Link className={currentPage.uri === item.url ? ( 'active' ) : ( null )} to={item.url}>{item.label}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				) : (
					null
				)}
				<header className="master-header video-header">
					{currentPage.headerBackground !== false && currentPage.headerBackground.vimeoId !== false ? (
						<>
							<img  src={`/rings.svg`} alt="loader" className="loader" />
							<iframe src={'https://player.vimeo.com/video/' + currentPage.headerBackground.vimeoId + '?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" class="fade-in'} title="Vimeo Video"></iframe>
							{/*
								<div className="container">
									<div className="page-header">
										<h1 className="page-title">{currentPage.title}</h1>
									</div>
								</div>
							*/}
						</>
					) : (
						null
					)}
				</header>
				{currentPage?.title &&
					<HeroTitle title={currentPage?.title} />
				}
				<section className="main-content">
					<div className="container">
						{currentPage.sidebarWidgetRelation !== null && currentPage.sidebarWidgetRelation.sidebarWidgetRelations !== null ? (
							<>
							<div className="uk-grid" data-uk-grid>
								<div className="uk-width-2-3@m main-col contact-us-page">
									{currentPage.svgInteractiveMap && currentPage.svgInteractiveMap.svgMap ? (
										<div dangerouslySetInnerHTML={{__html: currentPage.svgInteractiveMap.svgMap}} />
									) : (
										null
									)}

									<div dangerouslySetInnerHTML={{__html: currentPage.content}} />
									{currentPage.contactUs && currentPage.contactUs.location ? (
										<>
											{currentPage.contactUs.location[0].addresses.map((item, index) => (
												<div className="uk-grid contact-us-grid" data-uk-grid>
													<div className="uk-width-1-2@s left-column">
														<h4>{currentPage.contactUs.location[0].leftColumnTitle}</h4>
														<div key={index} dangerouslySetInnerHTML={{__html: item.physicalAddress}}></div>
													</div>
													<div className="uk-width-1-2@s right-column">
														<h4>{currentPage.contactUs.location[0].rightColumnTitle}</h4>
														{currentPage.contactUs.location[0].addresses.map((item, i) => (
															i === index ?
															<div key={index} dangerouslySetInnerHTML={{__html: item.mailingAddress}}></div>
															: null
														))}
													</div>
												</div>
											))}
										</>
									) : (
										null
									)}

								</div>
								<div className="uk-width-1-3@m">
									{currentPage.sidebarWidgetRelation.sidebarWidgetRelations.map((widget, index) => (
										<div className="operations-box" dangerouslySetInnerHTML={{__html: widget.content}} key={index} />
									))}
								</div>
							</div>
							</>
						) : (
							<>
								{currentPage.svgInteractiveMap && currentPage.svgInteractiveMap.svgMap ? (
									<div dangerouslySetInnerHTML={{__html: currentPage.svgInteractiveMap.svgMap}} />
								) : (
									null
								)}
								<div className="main-col" dangerouslySetInnerHTML={{__html: currentPage.content}} />
								{currentPage.contactUs && currentPage.contactUs.location ? (
									<>
										{currentPage.contactUs.location[0].addresses.map((item, index) => (
										<div className="uk-grid contact-us-grid" data-uk-grid>
											<div className="uk-width-1-2@s map(callbackfn: Function, thisArg?: any)">
												<h4>{currentPage.contactUs.location[0].leftColumnTitle}</h4>
												<div key={index} dangerouslySetInnerHTML={{__html: item.physicalAddress}}></div>
											))}
											</div>
											<div className="uk-width-1-2@s">
												<h4>{currentPage.contactUs.location[0].rightColumnTitle}</h4>
												{currentPage.contactUs.location[0].addresses.map((item, i) => (
													i === index ?
													<div key={index} dangerouslySetInnerHTML={{__html: item.mailingAddress}}></div>
													: null
												))}
											</div>
										</div>
										))}
									</>
								) : (
									null
								)}
							</>
						)}
					</div>
				</section>
			</Layout>
		)

	}
}

export default PageTemplate

export const pageQuery = graphql`
	query($id: String!) {

		wpPage(id: { eq: $id }) {
			uri
			title
			slug
			status
			content
			featuredImage {
				node {
					sourceUrl
				}
			}
			headerBackground {
				vimeoId
				videoPoster {
					sourceUrl
				}
			}
			sidebarWidgetRelation {
				sidebarWidgetRelations {
					... on WpSidebarWidgets {
						title
						content
					}
				}
			}
			contactUs {
				location {
					addresses {
						mailingAddress
						physicalAddress
					}
					leftColumnTitle
					rightColumnTitle
				}
			}
			svgInteractiveMap {
				svgMap
			}
			wpParent {
				node {
					slug
				}
			}
		}

		wpMenu(name: {eq: "About-submenu"}) {
			menuItems {
				nodes {
					url
					label
				}
			}
		}

		site {
			id
			siteMetadata {
				title
			}
		}

	}
`

